document.addEventListener('DOMContentLoaded', () => {
  checkSubmit()
  initScrollNav()
})

function checkSubmit () {
  const submitModal = document.getElementById('contact-modal')
  const closeButtons = Array.from(
    document.getElementsByClassName('close-modal')
  )
  const searchParams = new URLSearchParams(window.location.search)

  closeButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      submitModal.close()

      const url = new URL(window.location)
      url.searchParams.delete('success')
      window.history.replaceState({}, '', url.toString())
    })
  })

  if (searchParams.has('success') && !submitModal.open) {
    submitModal.showModal()
  }
}

function initScrollNav () {
  const sections = Array.from(
    document.getElementsByClassName('.content.section')
  )
  const navLinks = Array.from(
    document.querySelectorAll(
      '.navbar .menu a, .navbar-end .btn, .navbar .logo'
    )
  )

  // Smooth scrolling for nav links
  navLinks.forEach((link) => {
    link.addEventListener('click', function onClick (e) {
      e.preventDefault()
      const sectionId = this.getAttribute('href')
      const section = document.querySelector(sectionId)
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' })
      }
      document.getElementById('nav-drawer').checked = false
    })
  })

  const updateNavLinksActiveState = () => {
    sections.forEach((section, index) => {
      const sectionTop = index > 0 ? section.offsetTop - 80 : section.offsetTop
      const sectionBottom = section.offsetTop + section.offsetHeight
      if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
        navLinks.forEach((link, i) => {
          if (i === index) {
            link.classList.add('active')
          } else {
            link.classList.remove('active')
          }
        })
      }
    })
  }

  updateNavLinksActiveState()
  document.addEventListener('scroll', updateNavLinksActiveState)
}
